/** This is a temporary page, to allow the removal of the Contributors and Reviewers */

import * as React from "react";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { BreadCrumbs } from "@components/Breadcrumbs";
import { Layout } from "@/components/Layout";
import { Container } from "@/components/Container";

import { graphql } from "gatsby";
import { DynamicComponent } from "@components/DynamicComponent";
import { RenderMultiple } from "@components/RenderMultiple";
import { paramCase } from "change-case";
import { heroAtom, seoAtom } from "@/store";
import { Main } from "@components/Main";
import { Heading } from "@components/Heading";
import { Helmet } from "react-helmet";
import { useHydrateAtoms } from "jotai/utils";

const Contributors = ({ body, ep3Document }) => {
  return ep3Document?.heading ? (
    <div id={paramCase(ep3Document.heading)}>
      <Heading level={2} className="mb-4">
        {ep3Document.heading}
      </Heading>
      <DynamicComponent blocks={body} />
    </div>
  ) : null;
};

const ContributorsAndReviewers = ({ data }: any) => {
  const seo = { title: "Contributors And Reviewers", description: "Contact the EP3 team." };
  useHydrateAtoms([
    [heroAtom, data?.hero],
    [seoAtom, seo],
  ]);
  return (
    <>

      <Helmet>
        <title> {seo.title} | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container className="bg-white border-t-8 border-primary">
          <Header>
            <BreadCrumbs
              paths={[
                { url: "/", label: "Home" },
                { url: "/about", label: "About" },
              ]}
              title="Contributors And Reviewers"
            />
            <Title>Contributors And Reviewers</Title>
          </Header>
          <Main stretch={false}>
            <div className="[&_span]:max-w-[75ch] [&_ol]:max-w-[75ch] [&_ol]:list-decimal [&_figure]:border [&_figure]:border-gray-400 [&_figure]:p-5 [&_figure]:max-w-5xl [&_figure]:mb-5">
              <DynamicComponent blocks={data.allSanityCustomTextBlock.nodes[0].content} />
            </div>
            <RenderMultiple of={Contributors} fromPropsList={data.allSanityContributorsAndReviewers.nodes} />
          </Main>
        </Container>
      </Layout >
    </>
  );
};

export default ContributorsAndReviewers;

export const pageQuery = graphql`
  query ContributorsAndReviewers {
    hero: file(relativePath: { eq: "hero-random-page.jpg" }) {
      ...heroImageFragment
    }
    allSanityCustomTextBlock(filter: { target: { eq: "contributors-and-reviewers" } }) {
      nodes {
        content: _rawContent(resolveReferences: { maxDepth: 10 })
      }
    }
    allSanityContributorsAndReviewers(sort: { order: ASC, fields: ep3Document___heading }) {
      nodes {
        body: _rawBody(resolveReferences: { maxDepth: 10 })
        ep3Document {
          heading
        }
      }
    }
  }
`;
